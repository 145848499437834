import React, { useEffect, useState } from "react";


function Forum() {

  

  const url = typeof window !== 'undefined' ? window.location.href : '';
 
  return (
    <p>forum home! {url}</p>
  )
}
export default Forum
